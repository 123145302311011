/** @define wsmenu; weak */
/*.wsmenu--fixed {
  max-width: 1460px;
  margin: 0 auto;

  .wsmenu__list {
   @media only screen and (min-width: 900px) {
      padding: 10px 0;

      background-color: @white;
    }
    a {
      padding: 10px 20px;

      color: @black;
      line-height: 1.2;

      &.active {
        color: @orange;
      }
    }
  }

  .nav__dropdown-toggle {
    line-height: 1;
  }

  .nav button {
    padding: 10px 20px;

    color: @green;
  }
}*/
.wsmenu__list {
  position: absolute;
  z-index: 9;
  width: 100%;
  white-space: nowrap;
  /*@media only screen and (min-width: 1360px) {
    position: absolute;
    top: 24px;
    left: 180px;

    width: max-content;
  }*/
}
@media only screen and (max-width: 899px) {
  .wsmenu__list {
    background-color: rgba(247, 168, 16, 0.9);
  }
}
@media only screen and (min-width: 900px) {
  .wsmenu__list {
    position: absolute;
    top: 65px;
    display: table;
    width: 100%;
  }
}
@media only screen and (min-width: 1100px) {
  .wsmenu__list {
    top: 76px;
  }
}
.wsmenu__list li {
  display: inline-block;
}
@media only screen and (min-width: 900px) {
  .wsmenu__list li {
    display: table-cell;
  }
}
.wsmenu__list a {
  display: inline-block;
  padding: 14px 20px;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  font-family: montserratsemibold, arial, Helvetica, sans-serif;
}
@media only screen and (min-width: 1100px) {
  .wsmenu__list a {
    font-size: 17px;
  }
}
.wsmenu__list a span {
  padding-bottom: 10px;
  background-image: linear-gradient(#f7a810, #f7a810), linear-gradient(transparent, transparent);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 0 2px, auto;
  transition: all 0.2s ease-out;
}
@media only screen and (max-width: 899px) {
  .wsmenu__list a span {
    background-image: linear-gradient(#ffffff, #ffffff), linear-gradient(transparent, transparent);
  }
}
.wsmenu__list a em {
  font-style: normal;
  font-family: montserratsemibold, arial, Helvetica, sans-serif;
}
@media only screen and (max-width: 1050px) {
  .wsmenu__list a em {
    display: none;
  }
}
@media only screen and (min-width: 581px) {
  .wsmenu__list a.language {
    display: none;
  }
}
.wsmenu__list a:hover {
  text-decoration: none;
}
.wsmenu__list a:hover span {
  background-size: 100% 2px, auto;
}
.wsmenu__list a.active {
  color: #ffffff;
  font-weight: bold;
  font-family: montserratbold, arial, Helvetica, sans-serif;
  /*@media only screen and (min-width: 900px) {
        color: @green;
      }*/
}
.wsmenu__list a.active span {
  background-size: 100% 2px, auto;
}
.wsmenu__list--slideup {
  transform: translateY(-100px);
  transition: transform 0.5s ease-out;
}
.wsmenu__list--slidedown {
  transform: translateY(0);
  transition: transform 0.5s ease-out;
}
.wsmenu__list--slidedown svg,
.wsmenu__list--slidedown p {
  color: #f7a810;
}
/*.wsmenu__list--up {
  position: unset;
  top: unset;
  left: unset;
  z-index: 999;

  display: block;
  width: unset !important;
  height: unset;

  border: 1px solid red;

  line-height: unset;
  text-align: unset;

  background-color: unset;
  box-shadow: unset;
}*/
.wsmenu__list--down {
  position: fixed !important;
  top: 0 !important;
  z-index: 99;
  max-width: 1460px;
  margin: 0 auto;
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
@media only screen and (max-width: 899px) {
  .wsmenu__list--down {
    top: 70px !important;
    border: none;
  }
}
@media only screen and (min-width: 900px) {
  .wsmenu__list--down {
    padding: 10px 0;
    background-color: #ffffff;
  }
}
.wsmenu__list--down a {
  padding: 10px 20px;
  color: #000000;
  line-height: 1.2;
}
.wsmenu__list--down a.active {
  color: #f7a810;
}
/*# sourceMappingURL=css/menu.css.map */